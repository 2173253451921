import { DateString, ISODateString } from '../dto/visitor.dto';

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60;
export const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;
export const HOURS_12_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 12;
export const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;

export const DAY_IN_HOURS = 24;

export abstract class DateUtils {
  public static getDaysBetween(a: Date, b: Date): number {
    return Math.round((b.getTime() - a.getTime()) / DAY_IN_MILLISECONDS);
  }

  public static getHoursBetween(a: Date, b: Date): number {
    return Math.round((b.getTime() - a.getTime()) / HOUR_IN_MILLISECONDS);
  }

  public static generateDaysBetween(from: Date, to: Date): Date[] {
    const result = [];

    const toDate = this.endOfDay(to);

    let current = this.trimTime(from);

    while (current <= toDate) {
      result.push(current);

      current = this.addDays(current, 1);
    }

    return result;
  }

  public static getMinutesBetween(a: Date, b: Date): number {
    return Math.floor((b.getTime() - a.getTime()) / MINUTE_IN_MILLISECONDS);
  }

  public static addDays(date: Date, daysToAdd: number): Date {
    const result = new Date(date);

    result.setDate(result.getDate() + daysToAdd);

    return result;
  }

  public static addMilliseconds(date: Date, milliseconds: number): Date {
    return new Date(date.setMilliseconds(milliseconds));
  }

  public static addMinutes(date: Date, minutes: number) {
    return this.addMilliseconds(date, minutes * MINUTE_IN_MILLISECONDS);
  }

  public static addHours(date: Date, hours: number) {
    return this.addMilliseconds(date, hours * HOUR_IN_MILLISECONDS);
  }

  public static toUNIXTimestamp(date: Date): number {
    return Math.floor(date.getTime() / 1000);
  }

  public static toDateStringFromISO(dateString: ISODateString): DateString {
    return <DateString>dateString.substr(0, 10);
  }

  public static toDateString(date: Date): DateString {
    return this.toDateStringFromISO(<ISODateString>date.toISOString());
  }

  public static toTimezonelessISOString(date: Date): string {
    return `${ date.getFullYear() }-${ (date.getMonth() + 1).toString().padStart(2, '0') }-${ date.getDate().toString().padStart(2, '0') }T00:00:00.000Z`;
  }

  public static trimTime(dateWithTime: Date): Date {
    return new Date(Date.UTC(dateWithTime.getUTCFullYear(), dateWithTime.getUTCMonth(), dateWithTime.getUTCDate()));
  }

  public static endOfDayUTC(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23,
      59,
      59,
      59,
    );
  }

  public static endOfDay(date: Date): Date {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59,
      59,
    );
  }

  public static isSameDay(a: Date, b: Date) {
    return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
  }

  public static getDaysInMonth(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
    ).getDate();
  }
}

export class Month {
  constructor(
    public year: number,
    public month: number,
  ) {}

  public getNextMonth(): Month {
    const isLastMonth = this.month === 12;

    return new Month(
      isLastMonth ? this.year + 1 : this.year,
      isLastMonth ? 1 : this.month + 1,
    );
  }

  public getPreviousMonth(): Month {
    const isFirstMonth = this.month === 1;

    return new Month(
      isFirstMonth ? this.year - 1 : this.year,
      isFirstMonth ? 12 : this.month - 1,
    );
  }

  public static fromDate(date: Date): Month {
    return new Month(
      date.getUTCFullYear(),
      date.getUTCMonth() + 1,
    );
  }

  public toString(): string {
    return `${ this.year }-${ this.month.toString().padStart(2, '0') }`;
  }

  public toDateWithDayString() {
    return `${ this.toString() }-01`;
  }

  public compareTo(other: Month): number {
    if (this.year > other.year) {
      return 1;
    } else if (this.year === other.year) {
      if (this.month > other.month) {
        return 1;
      } else if (this.month === other.month) {
        return 0;
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  }

  public getMonthsBetween(chosenMonth: Month): number {
    return Math.abs(((this.year * 12) + this.month) - ((chosenMonth.year * 12) + chosenMonth.month));
  }
}

export class Day {
  constructor(
    public year: number,
    public month: number,
    public day: number,
  ) {}

  public compareTo(other: Day): number {
    if (this.year > other.year) {
      return 1;
    } else if (this.year === other.year) {
      if (this.month > other.month) {
        return 1;
      } else if (this.month === other.month) {
        if (this.day > other.day) {
          return 1;
        } else if (this.day === other.day) {
          return 0;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  }

  public addDays(daysToAdd: number): Day {
    return Day.fromDate(DateUtils.addDays(this.toDate(), daysToAdd));
  }

  public static fromDate(date: Date): Day {
    return new Day(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
    );
  }

  public static today() {
    return Day.fromDate(new Date());
  }

  public toDate(): Date {
    return new Date(Date.UTC(this.year, this.month - 1, this.day, 12));
  }

  // used to serialize
  public toISOString(): ISODateString {
    return <ISODateString>this.toDate().toISOString();
  }

  public toString(): DateString {
    return <DateString>`${ this.year }-${ this.month.toString().padStart(2, '0') }-${ this.day.toString().padStart(2, '0') }`;
  }

  public equals(day: Day): boolean {
    return this.year === day.year && this.month === day.month && this.day === day.day;
  }

  public static fromDateString(dateString: DateString | ISODateString): Day {
    const matches = dateString.match(/^(\d{4})-(\d{2})-(\d{2})/i);

    if (!matches) {
      throw new Error('Failed to parse date: ' + dateString);
    }

    return new Day(
      +matches[1],
      +matches[2],
      +matches[3],
    );
  }

  public endOfDay(): string {
    return `${ this.toString() }T23:59:59`;
  }
}

export interface DateRange {
  startDay: Day;

  endDay: Day;
}

export enum DateRangeLabel {
  Last7Days = 'last7days',
  Last30Days = 'last30days',
  Last60Days = 'last60days',
  Last90Days = 'last90days',
  Custom = 'custom',
}

export abstract class DateRangeUtils {
  public static calculateLabelFromRange(dateRange: DateRange): DateRangeLabel {
    if (!dateRange.startDay || !dateRange.endDay) {
      return DateRangeLabel.Custom;
    }

    const today = Day.today();

    if (dateRange.endDay.equals(today)) {
      const daysBetween = DateUtils.getDaysBetween(dateRange.startDay.toDate(), dateRange.endDay.toDate());

      if (daysBetween === 7) {
        return DateRangeLabel.Last7Days;
      } else if (daysBetween === 30) {
        return DateRangeLabel.Last30Days;
      } else if (daysBetween === 60) {
        return DateRangeLabel.Last60Days;
      } else if (daysBetween === 90) {
        return DateRangeLabel.Last90Days;
      }
    }

    return DateRangeLabel.Custom;
  }

  public static calculateRangeFromLabel(label: DateRangeLabel): DateRange {
    const today = Day.today();

    switch (label) {
      case DateRangeLabel.Last30Days:
        return {
          startDay: today.addDays(-30),
          endDay: today,
        };
      case DateRangeLabel.Last60Days:
        return {
          startDay: today.addDays(-60),
          endDay: today,
        };
      case DateRangeLabel.Last90Days:
        return {
          startDay: today.addDays(-90),
          endDay: today,
        };
      case DateRangeLabel.Custom:
        return {
          startDay: today.addDays(-4),
          endDay: today,
        };
      case DateRangeLabel.Last7Days:
      default:
        return {
          startDay: today.addDays(-7),
          endDay: today,
        };
    }
  }
}
